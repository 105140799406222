<template>
  <section class="home-content">
    <div class="container">
      <h1>Moje inzeráty</h1>
      <p>Vložení nemovitosti je snadné, jednoduché a rychlé! Za 10 minut máte hotovo. Za vložení inzerátu nic
        neplatíte.</p>
      <p class="center">
        <a @click="createAdvert" class="button">Vložit nemovitost<img src="../assets/images/plus.svg" data-uk-svg></a>
      </p>
      <advert-list></advert-list>

    </div>
  </section>
</template>
<script>

import advertList from "@/components/advertList";

export default {
  name: "adverts",
  components: {
    advertList
  },
  methods: {
    createAdvert() {
      let add = this.$store.dispatch('createNewAdvert');
      add.then((add) => this.$router.push({name: 'firstStep', params: {id: add.id}}));
    },
  },
}
</script>

<style scoped>

</style>